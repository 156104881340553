import React from "react";
import _ from "lodash";
import { Link } from "gatsby";
import { graphql, StaticQuery } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import PostCard from "../components/postCard";

import "../style/normalize.css";
import "../style/all.scss";

const TagIndex = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title;
  const tags = data.allMarkdownRemark.distinct;
  const posts = data.allMarkdownRemark.edges;
  let postCounter = 0;

  return (
    <Layout title={siteTitle}>
      <SEO title="Proyectos" />
      <header className="tag-page-head">
        <h1 className="page-head-title">Proyectos {/*({tags.length})*/}</h1>
      </header>
      <div className="tag-container">
        {tags.map(tag => {
          return (
            <Link key={tag} style={{ textDecoration: "none" }} to={`/tags/${_.kebabCase(tag)}`}>
              <div className="tag-item">#{tag}</div>
            </Link>
          );
        })}
      </div>
      <div className="post-feed">
        {posts.map(({ node }) => {
          postCounter++;
          return (
            <PostCard key={node.fields.slug} count={postCounter} node={node} postClass={`post`} />
          );
        })}
      </div>
    </Layout>
  );
};

const indexQuery = graphql`{
  site {
    siteMetadata {
      title
    }
  }
  allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC}) {
    distinct(field: frontmatter___tags)
    edges {
      node {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM D, YYYY")
          title
          description
          tags
          thumbnail {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
}
`;

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => <TagIndex location={props.location} props data={data} {...props} />}
  />
);
